import React from 'react';

export const Logo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 751.527 246.451"
    >
      <g id="calabasas-white-vector" transform="translate(-215.32 -726.58)">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M273.9,873.232H228.334q-13.018,0-13.014-13.4V740.384q0-13.4,13.014-13.4H273.9q13.4,0,13.4,13.266v34.209H265.472V745.54h-28.33V854.667h28.33V823.846h21.823v36.149Q287.295,873.237,273.9,873.232Z"
          transform="translate(0 -0.21)"
          fill="#fff"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M449.4,873.022,445,845.268H417.622l-4.4,27.754H391.59v-.381L419.919,726.58h22.974l28.139,146.062v.381ZM431.216,760.655,420.5,826.7h21.442Z"
          transform="translate(-92.428)"
          fill="#fff"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M584.36,873.243V727h21.823V853.717H641.79v19.525Z"
          transform="translate(-193.507 -0.22)"
          fill="#fff"
        />
        <path
          id="Path_30"
          data-name="Path 30"
          d="M779.01,873.022l-4.4-27.754H747.232l-4.4,27.754H721.2v-.381L749.53,726.58H772.5l28.139,146.062v.381ZM760.826,760.655,750.119,826.7h21.442Z"
          transform="translate(-265.26)"
          fill="#fff"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          d="M972.376,873.243H914V727h58q13.014,0,13.018,13.4v37.709q0,5.175-1.627,8.039a14.868,14.868,0,0,1-5.456,5.17l-9,5.551,9.575,5.936a15.218,15.218,0,0,1,5.551,5.361,16.45,16.45,0,0,1,1.722,8.229v43.455Q985.775,873.247,972.376,873.243Zm-9.19-127.692H935.813v42.88h16.843l10.531-7.463Zm.576,67.385-10.721-7.073H935.813v48.816h27.949Z"
          transform="translate(-366.355 -0.22)"
          fill="#fff"
        />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M1147.66,873.022l-4.4-27.754h-27.374l-4.4,27.754H1089.85v-.381l28.33-146.062h22.974l28.139,146.062v.381Zm-18.184-112.367L1118.755,826.7H1140.2Z"
          transform="translate(-458.562)"
          fill="#fff"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M1348.8,869.793q-3.448,3.444-9.76,3.439h-44.031q-6.312,0-9.765-3.477T1281.8,859.7V824.8h21.823v29.866h26.8V825.991l-43.84-41.476a14.914,14.914,0,0,1-3.748-4.966,17.547,17.547,0,0,1-1.051-6.692v-32.5q0-6.492,3.444-9.936T1295,726.98h44.031q6.312,0,9.76,3.482t3.448,9.865v32.8h-21.823V745.54h-26.788v26.379l43.835,41.286a16.857,16.857,0,0,1,3.734,5.161,17.543,17.543,0,0,1,1.056,6.692v34.789Q1352.252,866.354,1348.8,869.793Z"
          transform="translate(-559.207 -0.21)"
          fill="#fff"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M1513.085,873.022l-4.4-27.754H1481.3l-4.4,27.754H1455.27v-.381L1483.6,726.58h22.974l28.139,146.062v.381ZM1494.9,760.655,1484.18,826.7h21.437Z"
          transform="translate(-650.171)"
          fill="#fff"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M1714.239,869.8q-3.444,3.444-9.765,3.439h-44.035q-6.322,0-9.765-3.477t-3.443-10.06V824.812h21.823v29.866h26.8V826l-43.835-41.476a14.856,14.856,0,0,1-3.734-4.966,17.462,17.462,0,0,1-1.051-6.692v-32.5q0-6.493,3.443-9.936t9.765-3.434h44.026q6.321,0,9.765,3.482t3.444,9.865v32.791h-21.823V745.55h-26.8V771.93l43.836,41.286a16.95,16.95,0,0,1,3.734,5.161,17.556,17.556,0,0,1,1.051,6.692v34.789Q1717.683,866.364,1714.239,869.8Z"
          transform="translate(-750.826 -0.22)"
          fill="#fff"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M667.712,1125.182h-13.77v22.408h12.676v4.714H653.942v27.278H648.71V1120.54h19.026Z"
          transform="translate(-227.249 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M709.782,1179.582H704.55V1120.54h5.232Z"
          transform="translate(-256.529 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M755.114,1179.582H736.25V1120.54h5.232v54.4H755.1Z"
          transform="translate(-273.151 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M820.19,1179.582h-4.932V1142.2c0-.528.176-2.079.528-4.642l-9.67,36.477H805.06l-9.656-36.482q.528,3.891.528,4.642v37.386H791V1120.54h4.866l9.584,38.4a13.931,13.931,0,0,1,.147,1.7,14.357,14.357,0,0,1,.152-1.7l9.584-38.4H820.2Z"
          transform="translate(-301.859 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M894.941,1175.393a4.125,4.125,0,0,1-1.151,3.016,4.024,4.024,0,0,1-3,1.17H875.925a4.138,4.138,0,0,1-3.035-1.17,4.038,4.038,0,0,1-1.189-3.016v-14.978h5.232v14.522h12.747v-14.07l-16.3-19.692a6.864,6.864,0,0,1-1.7-4.49v-11.963a4.039,4.039,0,0,1,1.189-3.016,4.138,4.138,0,0,1,3.035-1.17h14.864a4.024,4.024,0,0,1,3,1.17,4.125,4.125,0,0,1,1.151,3.016v13.732h-5.232v-13.275H876.948v12.448l16.372,19.692a6.765,6.765,0,0,1,1.622,4.414Z"
          transform="translate(-344.166 -206.571)"
          fill="#fff"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M998.989,1151.1h-4.338v28.539H975.559a4.014,4.014,0,0,1-3-1.17,4.123,4.123,0,0,1-1.151-3.016v-20.072a4.19,4.19,0,0,1,1.9-3.772l4.419-2.854q-2.079-1.246-4.114-2.492a4.828,4.828,0,0,1-2.074-3.919v-17.547a4.081,4.081,0,0,1,1.17-3.016,4.02,4.02,0,0,1,2.978-1.17h14.94a4.159,4.159,0,0,1,3.039,1.17,4.053,4.053,0,0,1,1.184,3.016v12.367h-5.232v-11.943H976.729v17.765l5.323,3.549h16.938Zm-9.513,23.878V1151.1h-7.5l-5.394,3.468v20.41Z"
          transform="translate(-396.455 -206.608)"
          fill="#fff"
        />
        <path
          id="Path_42"
          data-name="Path 42"
          d="M1103.591,1179.582h-4.942V1142.2c0-.528.176-2.079.528-4.642l-9.656,36.482h-1.037l-9.66-36.482q.528,3.891.528,4.642v37.386h-4.942V1120.54h4.866l9.584,38.4a14.542,14.542,0,0,1,.152,1.7,14.122,14.122,0,0,1,.147-1.7l9.584-38.4h4.866Z"
          transform="translate(-450.466 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_43"
          data-name="Path 43"
          d="M1174.982,1179.582H1155.59V1120.54h19.164v4.642h-13.917v22.032h12.671v4.714h-12.671v23.012h14.146Z"
          transform="translate(-493.033 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_44"
          data-name="Path 44"
          d="M1237.9,1168.9a10.249,10.249,0,0,1-3.077,7.677,10.626,10.626,0,0,1-7.748,3H1213.57V1120.54h13.522a10.621,10.621,0,0,1,7.767,3,10.316,10.316,0,0,1,3.059,7.677Zm-5.232-.528V1131.67a6.3,6.3,0,0,0-1.826-4.7,6.494,6.494,0,0,0-4.757-1.788h-7.277v49.757h6.978a7.035,7.035,0,0,0,4.961-1.755,6.17,6.17,0,0,0,1.907-4.809Z"
          transform="translate(-523.435 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M1289.972,1179.582h-5.232V1120.54h5.232Z"
          transform="translate(-560.754 -206.574)"
          fill="#fff"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M1337.672,1179.414h-5.2l-2.078-13.128h-11.73l-2.079,13.128h-5.051v-.152l10.521-59.042h5.17Zm-7.958-17.77-5.17-31.992-5.232,31.992Z"
          transform="translate(-574.801 -206.406)"
          fill="#fff"
        />
      </g>
    </svg>
)

export default Logo