import React from 'react'
import { motion, useCycle } from "framer-motion"
import { Link } from 'gatsby';
import { use100vh } from "react-div-100vh"
import Logo from '../components/Logo';
import { Fade as Hamburger } from "hamburger-react";

function Header({isHome}) {
  const [isOpen, setOpen] = useCycle(false, true)
  const navigation = {
    open: {
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    },
    closed: {
      opacity: 0,
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      }
    }
  }
  const list = {
    open : {
      transition: {
        staggerChildren: 0.07, delayChildren: 0.2
      }
    }, 
    closed: {
      transition: {
        staggerChildren: 0.05, staggerDirection: -1
      }
    }
  }
  const item = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100}
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  }
  return (
    <>
      <header className="bg-primary h-nav flex items-center px-5 w-screen">
        <div className="container mx-auto flex justify-between">
          <div className="lg:w-32 w-28">
            { !isHome && <Link to="/"><Logo /></Link> }
          </div>
          <div className="hidden lg:block flex space-x-10 text-white uppercase font-heading text-2xl tracking-wider">
            <Link to='/'>Home</Link>
            <Link to='/about'>About</Link>
            <Link to='/contact'>Contact</Link>
          </div>
          <div className="lg:hidden z-50">
            <Hamburger size={40} color="white" toggle={setOpen} toggled={isOpen} /> 
          </div>
        </div>
      </header>

      <motion.div className={`${isOpen ? 'block' : 'hidden'} top-0 left-0 w-screen bg-primary flex absolute z-40`}
        animate={isOpen ? "open" : "closed"}
        initial={false}
        style={{height: use100vh()}}
        variants={navigation}
      >
        <motion.div variants={list} 
          className="text-center text-white font-sans text-6xl space-y-16 tracking-wider uppercase m-auto font-heading">
          <motion.div variants={item}><Link to="/">Home</Link></motion.div>
          <motion.div variants={item}><Link to="/about">About</Link></motion.div>
          <motion.div variants={item}><Link to="/contact">Contact</Link></motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}

export default Header
